/* General Body Styling */
body {
    background: linear-gradient(0deg, #00f2f8, #000000);
    color: #ffffff; /* White text */
}

/* Header Styling */
.header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Add this line */
    padding: 10px 20px;
    
    /* width: 100%; Ensure the header spans the full width */
}

/* Logo Styling */
.logo {
    width: 100px; /* Adjust the width as needed */
    height: 100px; /* Adjust the height as needed */
    background-image: url('/public/logo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 100px;
}

/* Heading Styling */
h1, h2 {
    color: #fff;
    margin-top: 0;
    margin-bottom: 10px;
    font-family: Public Sans, sans-serif;
    font-size: 48px;
    font-weight: 100;
    line-height: 48px;
}

/* Button Styling */
button {
    background-color: #00f2f8; /* Cyan button background */
    color: #0d1b2a; /* Dark button text */
    border: none;
    width: 200px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 20px;
    font-family: Public Sans, sans-serif;
    font-size: 12px;
    font-weight: 700;
    padding: 10px 0px;
    cursor: pointer;
    
    transition: all .2s;
    box-shadow: 0 0 3px 3px #ffffff4d;
}

button:hover {
    background-color: #ffffff; /* White on hover */
}

/* Input Styling */
input {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ffffff;
    background-color: #14213d;
    color: #ffffff;
    border-radius: 5px;
}

/* Styled Input */
.styled-input {
    width: 25%; 
    border: none;
    border-bottom: 2px solid #dbdbdb; 
    background-color: #0d1b2a00; 
    color: #ffffff;
    border-radius: 0px; 
    outline: none; 
    font-size: 16px;
}
.styled-input::placeholder {
    color: #ffffff;
    opacity: 0.5; 
  }

.styled-input:focus {
    border-bottom: 2px solid #00f2f8; 
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding: 50px 400px 400px 400px; 
}

table {
    border-collapse: collapse; 
    width: 100%;
    margin: 20px 0;
    overflow: hidden;
    font-family: Public Sans, sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px 10px 0 0;

}

th, td {
    border: none;
    padding: 12px 15px;
    text-align: left;
}

th {
    background-color: #00f4f8b6;
    color: #000000; 
    cursor: pointer; 
    letter-spacing: 1px;
    font-family: Public Sans, sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;

}

td {
    background-color: #006e7645;
    color: #ffffff;
    letter-spacing: 1px;
    text-transform: uppercase;
    justify-content: space-between;
    align-items: center;
    font-family: Public Sans, sans-serif;
    font-size: 18px;
    font-weight: 700;
    padding: 10px;

}

tr:nth-child(even) td {
    background-color: #4e9e9f56; 
}

tr:hover td {
    background-color: #4e9d9f;
    color: #ffffff;
}


.toast {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 16px 24px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  
  .toast.alert {
    background-color: #e53935;
    font-weight: bold;
    padding: 20px 30px;
  }
  
  .toast.fade-out {
    opacity: 0;
  }

/* Key Display Styling */
.key-display {
    background-color: #f3f3f3;
    color: #000000; 
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 20px;
    padding: 5px 20px;
    font-family: Public Sans, sans-serif;
    font-size: 12px;
    font-weight: 700;
    max-width: 300px;
    box-shadow: 0 0 3px 3px #ffffff4d;
    display: flex;
    flex-direction: row;    
    align-items: center;
}

/* Generated Key Text Styling */
.generated-key-text {
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 10px;
    border-radius: 20px;
    font-family: Public Sans, sans-serif;
    font-size: 12px;
    font-weight: 700;
    padding: 0px 20px;
    color: #1c1c1c; 
    word-wrap: break-word;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
}

.login-form {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.input-group {
    margin-bottom: 15px;
}

.input-group label {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 5px;
}

.input-group input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #218838;
}

.error-message {
    color: red;
    margin-top: 10px;
    text-align: center;
}

.redirecting-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #ccc;
    border-top-color: #333;
    border-radius: 50%;
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .redirecting-text {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #ffffff;
  }
  